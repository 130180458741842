export const logo = ['920 134', `
  <title>vodafone</title>
  <g transform="translate(0.000000,135.000000) scale(0.100000,-0.100000)" fill="#f00" stroke="none">
        <path d="M520 1320 c-248 -67 -430 -253 -490 -501 -25 -106 -25 -192 0 -298
        60 -251 245 -438 494 -501 152 -38 314 -19 466 57 110 55 232 179 287 293 96
        196 96 404 0 600 -33 68 -58 102 -127 170 -47 47 -106 96 -132 109 -43 23 -50
        24 -90 12 -62 -19 -142 -103 -162 -172 -23 -77 -21 -86 22 -100 112 -36 189
        -99 231 -188 22 -48 26 -70 26 -147 0 -83 -3 -95 -34 -158 -66 -128 -178 -198
        -321 -199 -110 0 -177 28 -261 112 -82 82 -110 147 -116 271 -17 303 237 573
        562 599 l80 6 -75 24 c-107 33 -259 38 -360 11z"/>
        <path d="M3774 1066 c-46 -20 -75 -67 -81 -131 -6 -53 -7 -55 -34 -55 -28 0
        -29 -2 -29 -50 0 -49 1 -50 30 -50 l30 0 0 -195 0 -195 60 0 60 0 0 195 0 195
        55 0 55 0 0 50 0 50 -55 0 c-51 0 -55 2 -55 23 0 12 7 33 16 46 15 20 23 23
        69 19 l53 -4 6 45 c4 26 2 49 -4 55 -17 17 -141 18 -176 2z"/>
        <path d="M2957 1063 c-4 -3 -7 -46 -7 -94 l0 -87 -59 5 c-70 7 -120 -8 -167
        -50 -61 -53 -79 -99 -79 -202 0 -82 3 -95 31 -145 57 -100 173 -137 252 -81
        l30 21 5 -21 c5 -18 12 -20 59 -17 l53 3 3 338 2 337 -58 0 c-32 0 -62 -3 -65
        -7z m-29 -279 c21 -6 22 -10 22 -135 0 -121 -1 -129 -22 -143 -12 -9 -40 -16
        -61 -16 -52 0 -93 43 -103 106 -20 133 53 217 164 188z"/>
        <path d="M2250 874 c-143 -62 -187 -276 -84 -411 80 -105 268 -105 348 0 102
        133 61 343 -79 408 -49 23 -136 24 -185 3z m139 -99 c77 -39 82 -218 7 -270
        -26 -19 -86 -19 -112 0 -74 51 -70 229 5 270 34 18 65 19 100 0z"/>
        <path d="M3270 873 c-79 -27 -83 -33 -59 -81 l21 -41 36 19 c74 40 162 19 162
        -38 0 -22 2 -21 -110 -38 -51 -8 -124 -51 -143 -84 -24 -39 -22 -115 3 -156
        23 -37 91 -74 136 -74 17 0 51 10 77 23 44 23 46 23 50 5 5 -16 13 -19 59 -16
        l53 3 0 185 c0 165 -2 189 -20 225 -38 77 -155 107 -265 68z m160 -306 c0 -48
        -3 -55 -25 -65 -34 -16 -98 -15 -113 0 -19 19 -14 66 8 86 24 20 53 30 98 31
        l32 1 0 -53z"/>
        <path d="M4084 871 c-49 -23 -107 -88 -122 -140 -16 -51 -14 -147 2 -194 35
        -98 111 -151 216 -151 142 0 229 96 229 254 1 107 -54 198 -141 234 -50 21
        -134 20 -184 -3z m163 -115 c33 -30 37 -39 41 -93 8 -108 -32 -173 -107 -173
        -70 0 -111 55 -111 148 0 68 17 111 54 133 46 28 81 24 123 -15z"/>
        <path d="M4661 870 c-52 -24 -51 -24 -51 -5 0 12 -13 15 -55 15 l-55 0 0 -245
        0 -246 58 3 57 3 3 175 2 175 33 23 c65 44 130 20 147 -53 5 -22 10 -104 10
        -182 l0 -143 61 0 61 0 -4 188 c-3 206 -13 244 -71 286 -40 30 -139 33 -196 6z"/>
        <path d="M5155 877 c-93 -37 -136 -103 -143 -218 -7 -123 33 -204 122 -252 57
        -30 181 -30 248 0 l47 21 -16 50 -16 51 -38 -20 c-41 -21 -121 -25 -163 -9
        -28 10 -51 42 -61 83 l-7 27 161 0 161 0 0 38 c0 56 -27 136 -57 173 -52 61
        -160 86 -238 56z m150 -120 c8 -12 15 -32 15 -44 0 -22 -3 -23 -90 -23 -88 0
        -90 1 -90 24 0 28 43 73 73 78 32 5 75 -11 92 -35z"/>
        <path d="M1620 788 c22 -51 68 -160 103 -243 l63 -150 54 0 54 0 94 230 c51
        127 96 236 99 243 6 15 -112 18 -122 2 -3 -5 -30 -74 -60 -152 -29 -78 -57
        -144 -62 -145 -4 -2 -35 67 -68 152 l-60 155 -67 0 -67 0 39 -92z"/>
    </g>
`]
