import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../style.css'
import { Auth } from 'aws-amplify'
import SimpleReactValidator from 'simple-react-validator'
import ConfirmSignup from '../confirmsignup'
import { toast } from 'react-toastify'
import { API_PATHS, METHOD } from '../../config/ApiConfig'


class Register extends Component {
    _isMounted = false;
    constructor() {
        super()
        this.state = {
            group: '',
            username: '',
            email: '',
            password: '',
            confirm_password: '',
            authenticationCode: '',
            message: '',
            group_error: '',
            tnc_error: '',
            confirm_password_error: '',
            email_error: '',
            color: '',
            tnc: false,
            confirmSignUp: 0,
            isSubmitted: false,
            cognito_groups: []
        }
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            element: message => <small className="text-danger">{message}</small>
        })
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchCognitoGroups()
            .then(data => {
                if (this._isMounted)
                    this.setState({ cognito_groups: data.data });
            })
            .catch(error => this.setState({ error: error.message }));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    fetchCognitoGroups = async () => {
        const response = await fetch(API_PATHS.FETCH_COGNITO);
        const data = await response.json();
        return data;
    }

    renderCognitoGroups = () => {
        return this.state.cognito_groups.map(id => {
            return (
                <option key={id} value={id}>{id}</option>
            )
        })
    }

    handleInputChange = event => {
        const name = event.target.name
        const value = name === 'tnc' ? event.target.checked : event.target.value
        this.setState({ [name]: value }, () => {
            if (name === 'email') {
                const emailDomain = this.state.email.split('@')[this.state.email.split('@').length - 1]
                if (emailDomain.toLocaleLowerCase() === 'vodafone.com') {
                    this.setState({ email_error: '' })
                } else {
                    this.setState({ email_error: 'Allowed email domains vodafone.com', color: 'danger' })
                }
            }
        })
    }

    signUp = (e) => {
        e.preventDefault()
        this.setState({ isSubmitted: true }, async () => {
            const { group, username, email, password, confirm_password, email_error } = this.state
            try {
                if (this.state.tnc && this.state.group && this.state.group !== 'Select' && this.validator.allValid() && !email_error && password === confirm_password) {
                    await Auth.signUp({ username, password, attributes: { email, 'custom:group': group } })
                    toast.success("Signup successful. Please check you mail for confirmation code.")
                    this.setState({ confirmSignUp: 1, isSubmitted: false })
                } else {
                    this.setState({ isSubmitted: false }, () => {
                        this.validator.showMessages()
                        if (!this.state.group)
                            this.setState({ group: 'Select', group_error: 'Group name is required.', color: 'danger' })
                        if (!this.state.tnc)
                            this.setState({ tnc_error: 'Please accept terms of use & privacy policy.', color: 'danger' })
                        if (password !== confirm_password)
                            this.setState({ confirm_password_error: 'Confirm password not matched.', color: 'danger' })
                        this.forceUpdate()
                    })
                }
            } catch (err) {
                this.setState({ isSubmitted: false }, () => {
                    if (err.code === "UsernameExistsException") {
                        toast.error("User already exist. Please login")
                    } else {
                        this.setState({ message: err.message, color: 'danger' }, () => setTimeout(() => this.setState({ message: '', color: '' }), 10000))
                    }
                })
            }
        })
    }

    render() {
        const { group, username, email, password, confirm_password, message, color, tnc, group_error, tnc_error, confirm_password_error, email_error, isSubmitted } = this.state
        return (
            <div className="signup-form mt-0 p-0 d-flex" >
                {
                    this.state.confirmSignUp === 0 && (
                        <div className="m-auto">
                            <small className={`text-${color} d-inline-block w-100`}>{message}</small>
                            <form>
                                <h3>Sign Up</h3>
                                <p>Please fill in this form to create an account!</p>
                                <hr />
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text px-2">
                                                <span className="fa fa-users"></span>
                                            </span>
                                        </div>
                                        <select name="group" className="form-control" onChange={this.handleInputChange} value={group}>
                                            <option defaultValue="Select" hidden>Select Group</option>
                                            {this.renderCognitoGroups()}
                                        </select>
                                    </div>
                                    <small className={`text-${color} d-${group === 'Select' && group_error ? 'inline-block' : 'none'} w-100`}>{group_error}</small>
                                </div>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <span className="fa fa-user"></span>
                                            </span>
                                        </div>
                                        <input type="email" className="form-control" name="username" placeholder="Username" value={username} onChange={this.handleInputChange} />
                                    </div>
                                    {this.validator.message('username', username, 'required|email')}
                                </div>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fa fa-paper-plane"></i>
                                            </span>
                                        </div>
                                        <input type="email" className="form-control" name="email" placeholder="Email Address" value={email} onChange={this.handleInputChange} />
                                    </div>
                                    {this.validator.message('email', email, 'required|email')}
                                    <small className={`text-${color} d-${email && email_error ? 'inline-block' : 'none'} w-100`}>{email_error}</small>
                                </div>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fa fa-lock"></i>
                                            </span>
                                        </div>
                                        <input type="password" className="form-control" name="password" placeholder="Password" value={password} onChange={this.handleInputChange} />
                                    </div>
                                    {this.validator.message('password', password, 'required|min:8|max:32')}
                                </div>
                                <div className="form-group">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                <i className="fa fa-lock"></i>
                                                <i className="fa fa-check"></i>
                                            </span>
                                        </div>
                                        <input type="password" className="form-control" name="confirm_password" placeholder="Confirm Password" value={confirm_password} onChange={this.handleInputChange} />
                                    </div>
                                    {this.validator.message('confirm_password', confirm_password, 'required|min:8|max:32')}
                                    <small className={`text-${color} d-${confirm_password !== password && confirm_password_error ? 'inline-block' : 'none'} w-100`}>{confirm_password_error}</small>
                                </div>
                                <div className="form-group">
                                    <label className="form-check-label">
                                        <input type="checkbox" name="tnc" value={tnc} onChange={this.handleInputChange} /> I accept the
                                        <a className="pointer" >Terms of Use</a> &amp; <a className="pointer" >Privacy Policy.</a>
                                    </label>
                                    <small className={`text-${color} d-${!tnc && tnc_error ? 'inline-block' : 'none'} w-100`}>{tnc_error}</small>
                                </div>
                                <div className="form-group">
                                    {!isSubmitted ? <button type="submit" className="btn btn-primary btn-lg" onClick={this.signUp}>Sign Up</button> :
                                        <button type="button" className="btn btn-primary btn-lg">
                                            <span className="spinner-border spinner-border-sm text-white" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </span>
                                        </button>
                                    }
                                </div>
                            </form>
                            <div className="text-center">Already have an account? <Link to="/login" className="login-text ml-1 pointer" >Login</Link></div>
                        </div>
                    )
                }
                {this.state.confirmSignUp === 1 && (
                    <ConfirmSignup {...this.props} username={username} message={message} color={color} />
                )
                }
            </div >
        )
    }
}

export default Register
