import { Auth } from 'aws-amplify'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../style.css'
import ConfirmSignup from '../confirmsignup'
import { toast } from 'react-toastify'

function Login(props) {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    /*const [remember, setRemember] = useState(false)*/
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [color, setColor] = useState('')
    const [message, setMessage] = useState('')
    const [isUserConfirmed, setIsUserConfirmed] = useState(true)

    async function signIn() {
        setIsSubmitted(true)
        
        try{
            const user = await Auth.signIn({ username, password })
            if(user){
                toast.info("Login Successful. Redirecting you to dashboard.")
                props.setUser(user)
            }
        } catch (err) {
            if (err.code === "UserNotConfirmedException") {
                toast.error("User not confirmed. Redirecting to verification page.")
                setIsUserConfirmed(false)
            } else {
                setMessage(err.message)
                setColor('danger')
                setTimeout(() => setMessage(''), 10000)
            }
        }
        
        setIsSubmitted(false)
    
    }

    return (
        <div className="signup-form mt-0 p-0 d-flex" >
            {isUserConfirmed && (
                <div className="m-auto">
                    <small className={`text-${color} text-center d-inline-block w-100`}>{message}</small>
                    <form>
                        <h3>Login</h3>
                        <p>Please login to use our services</p>
                        <hr />
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <span className="fa fa-user"></span>
                                    </span>
                                </div>
                                <input type="email" className="form-control" name="username" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className="fa fa-lock"></i>
                                    </span>
                                </div>
                                <input type="password" className="form-control" name="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group">
                            {!isSubmitted ? <button type="submit" className="btn btn-primary btn-lg" onClick={signIn}>Login</button> :
                                <button type="button" className="btn btn-primary btn-lg">
                                    <span className="spinner-border spinner-border-sm text-white" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </span>
                                </button>
                            }
                        </div>
                    </form>
                    <div className="text-center">Don't have an account? <Link to="/register" className="login-text pointer ml-1" >Register</Link> or <Link to="/forgot-password" className=" login-text pointer">Forgot Password?</Link></div>
                </div>
            )}
            {!isUserConfirmed && (<ConfirmSignup {...props} username={username} message={message} color={color} setIsUserConfirmed={setIsUserConfirmed} isUserConfirmed={isUserConfirmed} />)}
        </div>
    )
}

export default Login
