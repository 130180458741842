
import React from 'react';
import { Content, Sidebar, Header } from './index'

function Home(props) {
 
    return (<div className="c-app c-default-layout">
            <Sidebar />
            <div className="c-wrapper">
                <Header {...props} />
                <div className="c-body">
                   <Content />
                </div>
            </div>
        </div>)

}

export default Home
