import { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import '../style.css'
import ConfirmSignup from '../confirmsignup'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

function ForgotPasswordConfirmation(props) {

    return (
        <div className="signup-form mt-0 p-0 d-flex" >
         
                <div className="m-auto">
                    {/* <small className={`text-${color} text-center d-inline-block w-100`}>{message}</small> */}
                    <form>
                        <h3>Change Password</h3>
                        <p>Your password has been successfully updated!</p>
                        <hr/>
                        <Link to="/login">Go to Login</Link>
                    </form>
                </div>
            
         
        </div>
    )
}

export default ForgotPasswordConfirmation
