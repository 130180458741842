import axios from 'axios'
import { Auth } from 'aws-amplify';

const defaultOptions = {
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 25000,
    headers: {
        'Content-Type': 'application/json'
    }
}

export let instance = axios.create(defaultOptions)

const okeDefaultOptions = {
    baseURL: process.env.REACT_APP_OKE_API_URL,
    timeout: 25000,
    headers: {
        'Content-Type': 'application/json'
    }
}

export let okeInstance = axios.create(okeDefaultOptions)

export const recreateOKEInstance = async () => {
    const session = await Auth.currentSession()
    axios.defaults.headers.common['Authorization'] = session.idToken.jwtToken
    okeInstance = axios.create(okeDefaultOptions)
}

export const addAuthHeader = async () => {
    const session = await Auth.currentSession()
    axios.defaults.headers.common['Authorization'] = session.idToken.jwtToken
    instance = axios.create(defaultOptions)
}

export default instance
