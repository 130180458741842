import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios' 
import { Auth } from 'aws-amplify';
import {API_PATHS} from "../../config/ApiConfig"

export const fetchEksClusters = createAsyncThunk('eks/fetchEksClusters', async () => {
    let jwtToken = (await Auth.currentSession()).idToken.jwtToken;
    const response = await axios.get(API_PATHS.EKS, 
    {headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken
    }})
    if(response.status === 200){
        return response.data.data
    }
    return []
})

const initialState = {
    clusters: [],
    loading: 'idle',
    error: null
}

const listEksClusterSlice = createSlice({
    name: 'listEksCluster',
    initialState,
    extraReducers(builder) {
        builder.addCase(fetchEksClusters.pending, state => {
            state.loading = 'pending'
        })
        builder.addCase(fetchEksClusters.fulfilled, (state, action) => {
            state.loading = 'succeeded'
            state.clusters = action.payload
        })
        builder.addCase(fetchEksClusters.rejected, state => {
            state.loading = 'failed'
            state.error = "Something went wrong. Please contact GKS support."
        })
    }
})

export const getEksClusters = state => state.listEksCluster.clusters
export const isEksLoading = state => state.listEksCluster.loading
export const getEksError = state => state.listEksCluster.error

export default listEksClusterSlice.reducer
