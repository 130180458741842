// API Base URL
export const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
export const REACT_APP_GKE_API_URL = process.env.REACT_APP_GKE_API_URL
export const REACT_APP_OKE_API_URL = process.env.REACT_APP_OKE_API_URL
export const REACT_APP_API_APIGEE_URL = process.env.REACT_APP_API_APIGEE_URL
export const REACT_APP_BASTION_API = process.env.REACT_APP_BASTION_API
export const REACT_APP_K8_IMAGES_API = process.env.REACT_APP_K8_IMAGES_API
export const REACT_APP_NODE_SIZE = process.env.REACT_APP_NODE_SIZE

// API Paths
export const API_PATHS = {
    // EKS API PATHS
    EKS: REACT_APP_API_URL + "/eks",
    EXISTING_VPCS: REACT_APP_API_URL + "/target/existingvpcs",
    ACCOUNT_IDS: REACT_APP_API_URL + "/account-ids",
    FETCH_AMIS: REACT_APP_API_URL + "/fetchAMIS",
    FETCH_COGNITO: REACT_APP_API_URL + "/getCognitoGroups",
    EXISTING_VPC_SETUP: REACT_APP_API_URL + "/existingVPCSetup",
    INSTANCE_TYPES: REACT_APP_API_URL + "/fetchInstanceType",

    // GKE API PATHS
    GKE: REACT_APP_GKE_API_URL + "/gke", 
    FETCH_KV_GKE: REACT_APP_GKE_API_URL + "/getKubernetesVersions",

    // OKE API PATHS
    OKE: REACT_APP_OKE_API_URL + "/oke",
    FETCH_KV_OKE: REACT_APP_OKE_API_URL + "/getKubernetesVersions",
    COMPARTMENT_IDS: REACT_APP_OKE_API_URL + "/getCompartmentIds",
    K8IMAGE: REACT_APP_K8_IMAGES_API + "/Stage/fetchImages",
    BASTION_VIEW: REACT_APP_BASTION_API + "/Stage/ViewBastions",
    BASTION_LIST: REACT_APP_BASTION_API + "/Stage/Bastion",
    BASTION_CREATE: REACT_APP_BASTION_API + "/Stage/oke",
    BASTION_DELETE: REACT_APP_BASTION_API + "/Stage/delete",
    NODESIZE: REACT_APP_NODE_SIZE + "/Stage/fetchNodepoolSize",


    // APIGEE
    APIGEE: REACT_APP_API_APIGEE_URL + "/",
    
};

// API Methods
export const METHOD = {
    GET: "GET",
    POST: "POST",
    DELETE: "DELETE",
    UPDATE: "PUT"
}
