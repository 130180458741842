// libraries
import {Suspense, useState, useEffect} from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Amplify from 'aws-amplify'
import { ToastContainer } from 'react-toastify'
import { Auth } from 'aws-amplify';
// modules
import awsconfig from './aws-exports'
import 'react-toastify/dist/ReactToastify.css'
// components
import Register from './pages/register'
import Login from './pages/login'
import ForgotPassword from './pages/forgotpassword'
import {Home} from './containers'
import ForgotPasswordVerification from "./pages/forgotpasswordverification"
import ForgotPasswordConfirmation from "./pages/forgotpasswordconfirmation"
Amplify.configure(awsconfig)

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)

function App() {
    const [user, setUser] = useState(null)
    
    function processAuthentication(authSuccess){
        if(authSuccess){
            setUser(authSuccess)
        } else {
            setUser(null)
        }
    }
    
    useEffect(() => {
        try {
            Auth.currentAuthenticatedUser().then((user) => {
                setUser(user)
            }).catch((error) => {
                setUser(null)
                console.error(error)
            })
        } catch (error) {
           console.error(error)
        }
    }, [])
    
    if(!user){
        return<Router>
            <Switch>
                <Suspense fallback={loading}>
                    <ToastContainer />
                    <Route exact path="/">
                        <Login setUser={processAuthentication}/>
                    </Route>
                    <Route path="/register">
                      <Register />
                    </Route>
                    <Route path="/forgot-password">
                      <ForgotPassword />
                    </Route>
                    <Route path="/forgot-password-confirmation">
                      <ForgotPasswordConfirmation />
                    </Route>
                    <Route path="/forgot-password-verification">
                      <ForgotPasswordVerification />
                    </Route>
                    <Route path="/login">
                        <Login setUser={processAuthentication}/>
                    </Route>
                </Suspense>
            </Switch>
        </Router> 
    }
    
    return (
        <Router>
            <Switch>
                <Suspense fallback={loading}>
                    <ToastContainer />
                    <Route  path="/">
                        <Home setUser={processAuthentication}/>
                    </Route>
                </Suspense>
            </Switch>
        </Router>
    )
}

export default App
