import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from '../../config/axios'

export const fetchAmis = createAsyncThunk('eks/fetchAmis', async (body) => {
    const res = await axios.post('fetchAMIS', JSON.stringify(body))
    if (!res.data.data) return res.data.message
    return res.data.data
})

const initialState = {
    amis: [],
    latest_kv: '',
    loading: 'idle',
    error: null
}

const eksAmisSlice = createSlice({
    name: 'eksAmis',
    initialState,
    extraReducers(builder) {
        builder.addCase(fetchAmis.pending, state => {
            state.loading = 'pending'
        })
        builder.addCase(fetchAmis.fulfilled, (state, action) => {
            if (typeof action.payload === 'string') {
                state.loading = 'failed'
                state.error = action.payload
                return
            }
            state.loading = 'succeeded'
            state.amis = action.payload
            state.latest_kv = action.payload
                .filter(e => e.deprecated === "False")
                .map(e => e.kubernetes_version)
                .sort((a, b) => Number(b) - Number(a))
                .slice(0, 3)[0]
        })
        builder.addCase(fetchAmis.rejected, (state, action) => {
            console.log("failed fetchAMIs")
            state.loading = 'failed'
            state.error = action.error.message
        })
    }
})

export const getAmis = state => state.eksAmis.amis
export const getLatestKv = state => state.eksAmis.latest_kv
export const isAmisLoading = state => state.eksAmis.loading
export const getAmisError = state => state.eksAmis.error

export default eksAmisSlice.reducer
