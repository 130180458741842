import { Auth } from 'aws-amplify'
import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import '../style.css'
import ConfirmSignup from '../confirmsignup'
import { toast } from 'react-toastify'

function ForgotPassword(props) {
    const [username, setUsername] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [recoveryTriggered, setRecoveryTriggered] = useState(false)
    const [color] = useState('')
    const [message] = useState('')
    const [isUserConfirmed, setIsUserConfirmed] = useState(true)

    async function signIn() {
        setIsSubmitted(true)
        try {
            await Auth.forgotPassword(username)
            toast.info("Password reset code was send via email.")
            setRecoveryTriggered(true)
        } catch (err) {
            toast.error(err.message)
            toast.error("Password recovery failed. Please contact GKS support.")
            setRecoveryTriggered(false)
        }
        setIsSubmitted(false)
    }

    return ( recoveryTriggered ? <Redirect to="/forgot-password-verification"/> :
        <div className="signup-form mt-0 p-0 d-flex" >
            {isUserConfirmed && (
                <div className="m-auto">
                    <small className={`text-${color} text-center d-inline-block w-100`}>{message}</small>
                    <form>
                        <h3>Forgot your password?</h3>
                        <p>Please enter your registered email.</p>
                        <hr />
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <span className="fa fa-user"></span>
                                    </span>
                                </div>
                                <input type="email" className="form-control" name="username" placeholder="E-mail" value={username} onChange={(e) => setUsername(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group">
                            {!isSubmitted ? <button type="submit" className="btn btn-primary btn-lg" onClick={signIn}>SUBMIT</button> :
                                <button type="button" className="btn btn-primary btn-lg">
                                    <span className="spinner-border spinner-border-sm text-white" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </span>
                                </button>
                            }
                        </div>
                    </form>
                    <div className="text-center">Don't have an account? <Link to='/register' className="login-text ml-1 pointer" >Register</Link></div>
                </div>
            )}
            {!isUserConfirmed && (<ConfirmSignup {...props} username={username} message={message} color={color} setIsUserConfirmed={setIsUserConfirmed} isUserConfirmed={isUserConfirmed} />)}
        </div>
    )
}

export default ForgotPassword
