import { withRouter, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
    CHeader,
    CToggler,
    CHeaderBrand,
    CHeaderNav,
    CHeaderNavItem,
    CHeaderNavLink,
    CSubheader,
    CBreadcrumbRouter
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

// routes config
import routes from '../routes'
import { useLocation } from 'react-router';
import { Auth } from 'aws-amplify'
import { changeState, getSidebarShowValue } from 'src/store/settings-slice'

const Header = (props) => {
    let history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch()
    const sidebarShow = useSelector(getSidebarShowValue)

    const toggleSidebar = () => {
        const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
        dispatch(changeState(val))
    }

    const toggleSidebarMobile = () => {
        const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
        dispatch(changeState(val))
    }

    async function signOut() {
        await Auth.signOut()
        props.setUser(null)
        history.push("/login");
    }

    return (
        <CHeader withSubheader>
            <CToggler
                inHeader
                className="ml-md-3 d-lg-none"
                onClick={toggleSidebarMobile}
            />
            <CToggler
                inHeader
                className="ml-3 d-md-down-none"
                onClick={toggleSidebar}
            />
            <CHeaderBrand className="mx-auto d-lg-none" to="/">
                <CIcon name="logo" height="48" alt="Logo" />
            </CHeaderBrand>

            <CHeaderNav className="d-md-down-none mr-auto">
                <CHeaderNavItem className="px-3" >
                    <CHeaderNavLink to="/dashboard">Dashboard</CHeaderNavLink>
                </CHeaderNavItem>
                <CHeaderNavItem className={"px-3 " + (location.pathname === "/aws/clusters" || location.pathname === "/aws/clusters/create" || location.pathname === "/aws/clusters/update" || location.pathname === "/dashboard" ? '' : 'd-none')}>
                    <CHeaderNavLink to="/aws/clusters">EKS Clusters</CHeaderNavLink>
                </CHeaderNavItem>
                <CHeaderNavItem className={"px-3 " + (location.pathname === "/gcp/gks" ? '' : 'd-none')}>
                    <CHeaderNavLink to="/gcp/gks">GKS Clusters</CHeaderNavLink>
                </CHeaderNavItem>
                <CHeaderNavItem className={"px-3 " + (location.pathname === "/azure/aks" ? '' : 'd-none')}>
                    <CHeaderNavLink to="/azure/aks">AKS Clusters</CHeaderNavLink>
                </CHeaderNavItem>
            </CHeaderNav>

            <CHeaderNav className="px-3 amp-button-style">
                <a
                    onClick={signOut}
                    className="btn btn-danger text-white"
                >
                    <i className="fas fa-sign-out-alt mfe-2 text-white"></i>Sign out
                </a>
            </CHeaderNav>

            <CSubheader className="px-3 justify-content-between">
                <CBreadcrumbRouter
                    className="border-0 c-subheader-nav m-0 px-0 px-md-3"
                    routes={routes}
                />
            </CSubheader>
        </CHeader>
    )
}

export default withRouter(Header)