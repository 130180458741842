const awsconfig = {
    Auth: {
        region: 'eu-central-1',
        userPoolId: 'eu-central-1_08VBsqHtV',
        userPoolWebClientId: '6nnrtevf806ajkihd8dod4o32v',
        identityPoolId: 'eu-central-1:cb417ec6-0bca-430f-9040-a365940e5c5c',
    },
    API: {
        endpoints: [
            {
                name: 'listEKS',
                endpoint: 'https://k1xsj6to0g.execute-api.eu-central-1.amazonaws.com'
            }
        ]
    },
    storage: window.sessionStorage
};

export default awsconfig