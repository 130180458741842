import { configureStore } from "@reduxjs/toolkit";
import settingsReducer from "./settings-slice";
import listEksClustersReducer from "./eks/listEksClusterSlice";
import getEksClusterReducer from "./eks/getEksClusterSlice";
import eksAmisReducer from "./eks/amisSlice";
import okeReducer from "./oke/okeSlice";

const store = configureStore({
    reducer: {
        settings: settingsReducer,
        listEksCluster: listEksClustersReducer,
        getEksCluster: getEksClusterReducer,
        eksAmis: eksAmisReducer,
        oke: okeReducer,
    }
})

export default store
