const _nav = [
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Components']
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'AWS',
    route: '/aws',
    icon: 'cil-puzzle',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'EKS Clusters',
        to: '/aws/clusters',
      }
    ]
  },
  {
    _tag: 'CSidebarNavDivider'
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'GCP',
    route: '/gcp',
    icon: 'cil-puzzle',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'GKE Clusters',
        to: '/gcp/gks',
      }
    ]
  },
  {
    _tag: 'CSidebarNavDivider'
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Azure',
    route: '/azure',
    icon: 'cil-puzzle',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'AKS',
        to: '/azure/aks',
      }
    ]
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Oracle',
    route: '/oracle',
    icon: 'cil-puzzle',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'OKE',
        to: '/oke/clusters',
      }
    ]
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Services',
    route: '/bastions',
    icon: 'cil-puzzle',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Bastions',
        to: '/bastions',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Schedules',
        to: '/schedules',
      }
    ]
  },
]

export default _nav
