import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    sidebarShow: 'responsive'
}

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        changeState: (state, action) => {
            state.sidebarShow = action.payload
        }
    }
})

export const { changeState } = settingsSlice.actions

export const getSidebarShowValue = state => state.settings.sidebarShow

export default settingsSlice.reducer