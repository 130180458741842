import React from 'react';
import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client'
import { Provider } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faPlusCircle, faEye, faEdit, faTrash, faList, faUndo, faTimes } from '@fortawesome/free-solid-svg-icons';
import App from './App';
import { icons } from './assets/icons';
import store from './store';
import './assets/scss/style.scss';
import { Auth } from 'aws-amplify';
import axios, { okeInstance } from './config/axios';

React.icons = icons;
library.add(fab, faPlusCircle, faEye, faEdit, faTrash, faList, faUndo, faTimes);

Auth.currentSession()
  .then(currentSession => {
    axios.defaults.headers.common['Authorization'] = currentSession.idToken.jwtToken
    okeInstance.defaults.headers.common['Authorization'] = currentSession.idToken.jwtToken
  })
  .catch(() => {
    delete axios.defaults.headers.common['Authorization']
    delete okeInstance.defaults.headers.common['Authorization']
  });
  
const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
