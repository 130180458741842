import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../style.css'
import { Auth } from 'aws-amplify'
import { toast } from 'react-toastify'

class ConfirmSignup extends Component {
    constructor() {
        super()
        this.state = {
            username: '',
            authenticationCode: '',
            message: '',
            color: '',
            isSubmitted: false
        }
    }

    componentDidMount() {
        const { username, message, color } = this.props
        this.setState({ username, message, color })
    }

    handleInputChange = event => {
        const name = event.target.name
        const value = name === 'tnc' ? event.target.checked : event.target.value
        this.setState({ [name]: value }, () => {
            if (name === 'email') {
                const emailDomain = this.state.email.split('@')[this.state.email.split('@').length - 1]
                if (emailDomain.toLocaleLowerCase() === 'vodafone.com' || emailDomain.toLocaleLowerCase() === 'shunyeka.com') {
                    this.setState({ email_error: '' })
                } else {
                    this.setState({ email_error: 'Allowed email domains vodafone.com|shunyeka.com.', color: 'danger' })
                }
            }
        })
    }

    confirmSignUp = () => {
        this.setState({ isSubmitted: true }, async () => {
            const { username, authenticationCode } = this.state
            try {
                await Auth.confirmSignUp(username, authenticationCode)
                toast.success("User confirmation successful. Please login to continue.")
                this.setState({ isSubmitted: false })
            } catch (err) {
                this.setState({ message: err.message, color: 'danger', isSubmitted: false }, () => setTimeout(() => this.setState({ message: '', color: '' }), 10000))
                if (err.code === "NotAuthorizedException") {
                    toast.error("Not authorized to perform the action.")
                    this.props.setIsUserConfirmed(true)
                }
            }
        })
    }

    resendSignUp = async () => {
        const { username } = this.state
        try {
            await Auth.resendSignUp(username)
            this.setState({ color: "success", message: "Verification code sent successfully." }, () => setTimeout(() => this.setState({ message: '', color: '' }), 10000))
        } catch (err) {
            this.setState({ message: err.message, color: 'danger' }, () => setTimeout(() => this.setState({ message: '', color: '' }), 10000))
        }
    }

    render() {
        const { username, message, color, authenticationCode, isSubmitted } = this.state
        return (
            <div className="m-auto">
                <small className={`text-${color} text-center d-inline-block w-100`}>{message}</small>
                <form>
                    <h3>Confirm SignUp</h3>
                    <p>Enter verification code sent to your email.</p>
                    <hr />
                    <div className="form-group">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <span className="fa fa-user"></span>
                                </span>
                            </div>
                            <input type="email" className="form-control" name="username" value={username} disabled />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <i className="fa fa-lock"></i>
                                </span>
                            </div>
                            <input type="text" className="form-control" name="authenticationCode" placeholder="Authentication Code" value={authenticationCode} onChange={this.handleInputChange} maxLength='6' />
                        </div>
                    </div>
                    <div className="form-group">
                        <label className="form-check-label float-left pb-2">
                            <Link  className="pointer" to="/login" >Back to login</Link>
                        </label>
                        <label className="form-check-label float-right pb-2">
                            <a  className="pointer" onClick={this.resendSignUp} >Resend code</a>
                        </label>
                    </div>
                    <div className="form-group">
                        {!isSubmitted ? <button type="button" className="btn btn-primary btn-lg" onClick={this.confirmSignUp}>Confirm</button> :
                            <button type="button" className="btn btn-primary btn-lg">
                                <span className="spinner-border spinner-border-sm text-white" role="status">
                                    <span className="sr-only">Loading...</span>
                                </span>
                            </button>
                        }
                    </div>
                </form>
            </div >
        )
    }
}

export default ConfirmSignup
