import { Auth } from 'aws-amplify'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../style.css'
import ConfirmSignup from '../confirmsignup'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


function ForgotPasswordVerification(props) {
    const [username, setUsername] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [code, setCode] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [color] = useState('')
    const [message] = useState('')
    const [isUserConfirmed, setIsUserConfirmed] = useState(true)
    const [passwordErr, setPasswordErr] = useState({})

    const passwordValidation = () => {
        const passwordErr = {}
        let isValid = true
        if (newPassword.trim().length < 8) {
            passwordErr.PasswordShort = "Password should be minimum 8 characters";
            isValid = false;
        }
        if (!newPassword.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/)) {
            passwordErr.PasswordErr = "Password must contain one upper case,number and special character";
            isValid = false;
        }
        setPasswordErr(passwordErr);
        return isValid;
    }

    async function signIn() {
        setIsSubmitted(true)
        try {
            await Auth.forgotPasswordSubmit(username, code, newPassword)
            setIsSubmitted(false)
        } catch (err) {
            toast.error(err.message)
            setIsSubmitted(false)
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const isValid = passwordValidation()
        if (isValid) {
            if (newPassword === confirmPassword) {
                signIn()
            } else {
                toast.error("Passwords confirmation does not match")
            }
        }
    }

    return (
        <div className="signup-form mt-0 p-0 d-flex" >
            {isUserConfirmed && (
                <div className="m-auto">
                    <small className={`text-${color} text-center d-inline-block w-100`}>{message}</small>
                    <form>
                        <h3>Set new password</h3>
                        <p>Please enter verification code sent on your email.</p>
                        <hr />
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend"></div>
                                <input type="text" className="form-control" name="code" placeholder="Enter verification code " value={code} onChange={(e) => setCode(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <span className="fa fa-user"></span>
                                    </span>
                                </div>
                                <input type="email" className="form-control" name="Enter username" placeholder="Email" value={username} onChange={(e) => setUsername(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className="fa fa-lock"></i>
                                    </span>
                                </div>
                                <input type="password" className="form-control" name="newPassword" placeholder="Enter new password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                            </div>
                            {Object.keys(passwordErr).map((key) => {
                                return <div style={{ color: "red" }}>{passwordErr[key]}</div>
                            })}
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className="fa fa-lock"></i>
                                    </span>
                                </div>
                                <input type="password" className="form-control" name="confirmPassword" placeholder="Confirm password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                <p>Your password must be more than 8 characters long, should contain at-least 1 Uppercase, 1 Lowercase, 1 Numeric and 1 special character.</p>
                            </div>
                        </div>

                        <div className="form-group">
                            {!isSubmitted ? <button type="submit" className="btn btn-primary btn-lg" onClick={onSubmit}>SUBMIT</button> :
                                <button type="button" className="btn btn-primary btn-lg">
                                    <span className="spinner-border spinner-border-sm text-white" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </span>
                                </button>
                            }
                        </div>
                    </form>
                    <div className="text-center">Don't have an account? <Link to="/register" className="login-text ml-1 pointer" >Register</Link> or <Link to="/login" className="login-text ml-1 pointer" >Login</Link> </div>
                </div>
            )}
            {!isUserConfirmed && (<ConfirmSignup {...props} username={username} message={message} color={color} setIsUserConfirmed={setIsUserConfirmed} isUserConfirmed={isUserConfirmed} />)}
        </div>
    )
}

export default ForgotPasswordVerification
