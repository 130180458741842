import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios, { okeInstance, recreateOKEInstance } from '../../config/axios'

export const fetchOkeVcns = createAsyncThunk('oke/fetchOkeVcns', async (payload) => {
    if(!axios?.defaults?.headers?.common['Authorization']){
      await recreateOKEInstance()
    }
    const res = await okeInstance.post('fetchVCN', payload)
    return res.data.data;
})

export const getVcnDetails = async (payload) => {
    if(!axios?.defaults?.headers?.common['Authorization']){
      await recreateOKEInstance()
    }
    const res = await okeInstance.post('fetchVCN', payload)
    return res.data.data;
}

export const fetchOkeSubCompartment = createAsyncThunk('oke/fetchOkeSubCompartment', async (payload) => {
     if(!axios?.defaults?.headers?.common['Authorization']){
      await recreateOKEInstance()
    }
    const res = await okeInstance.post('getSubcompartments', payload)
    return res.data.data
})

const initialState = {
    vcns: [],
    bastions: [],
    buckets: [],
    proxy_url:[],
    compartments: [],
    sub_compartments: [],
    vcn_loading: 'idle',
    compartment_loading: 'idle',
    sub_compartment_loading: 'idle',
    takenClusterNames: [],
    error: null
}

const okeSlice = createSlice({
    name: 'oke',
    initialState,
    reducers: {
        resetLoading(state) {
            state.vcn_loading = 'idle'
            state.sub_compartment_loading = 'idle'
        },
        resetError(state) {
            state.error = null
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchOkeVcns.pending, state => {
            state.vcn_loading = 'pending'
        })
        builder.addCase(fetchOkeVcns.fulfilled, (state, action) => {
            state.vcn_loading = 'succeeded'
            state.vcns = action.payload.network_details
            state.bastions = action.payload.bastion_details
            state.proxy_url = action.payload.proxy_url
            state.buckets = action.payload.bucket_details
            state.takenClusterNames = action.payload.cluster_details
        })
        builder.addCase(fetchOkeVcns.rejected, state => {
            state.vcn_loading = 'failed'
            state.error = "Something went wrong. Please contact OKE support."
        })
        builder.addCase(fetchOkeSubCompartment.pending, state => {
            state.sub_compartment_loading = 'pending'
        })
        builder.addCase(fetchOkeSubCompartment.fulfilled, (state, action) => {
            state.sub_compartment_loading = 'succeeded'
            state.sub_compartments = action.payload.subcompartments;
            state.regions = action.payload.regions;
        })
        builder.addCase(fetchOkeSubCompartment.rejected, state => {
            state.sub_compartment_loading = 'failed'
            state.error = "Something went wrong. Please contact OKE support."
        })
    }
})

export const getVcns = state => state.oke.vcns
export const getBastions = state => state.oke.bastions
export const getBuckets = state => state.oke.buckets
export const getProxy = state => state.oke.proxy_url
export const getSubComartments = state => state.oke.sub_compartments
export const getRegions = state => state.oke.regions
export const getTakenClusterNames = state => state.oke.takenClusterNames
export const isVcnLoading = state => state.oke.vcn_loading
export const isCompLoading = state => state.oke.compartment_loading
export const isSubCompLoading = state => state.oke.sub_compartment_loading
export const isError = state => state.oke.error

export const { resetLoading, resetError } = okeSlice.actions

export default okeSlice.reducer
