import React from "react";

const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const Cluster = React.lazy(() => import("./views/eks/Cluster"));
const AddCluster = React.lazy(() =>
  import("./components/eks/addcluster/AddCluster")
);

const UpdateCluster = React.lazy(() =>
  import("./components/eks/updatecluster/UpdateCluster")
);
const GetCluster = React.lazy(() =>
  import("./components/eks/getcluster/GetCluster")
);

const GetGKECluster = React.lazy(() =>
  import("./components/gke/getcluster/GetGKECluster")
);
const Users = React.lazy(() => import("./views/eks/Users"));
const RequestGKS = React.lazy(() => import("./views/gks/ListGkeClusters"));
const RequestAKS = React.lazy(() => import("./views/aks/RequestAKS"));


const GkeForm = React.lazy(() =>
  import("./components/gke/addcluster/AddClusterGke")
);
const ListGkeClusters = React.lazy(() => import("./views/gks/ListGkeClusters"));

const ExistingVPC = React.lazy(() => import("./views/eks/ExistingVPC"));
const ExistingVpcCluster = React.lazy(() => import("./views/eks/ExistingVpcCluster"));
const UpdateGkeCluster = React.lazy(() => import("./components/gke/updatecluster/UpdateGkeCluster"))


const GetExsitingVPC = React.lazy(() => import("./views/eks/GetExisting"))
const UpdateExsitingVPC = React.lazy(() => import("./views/eks/updateExisting/UpdateExisting"))

// schedules
const Schedules = React.lazy(() => import("./views/schedules"))
const ScheduleDetails = React.lazy(() => import("./views/schedules/ScheduleDetails"))
const AddSchedule = React.lazy(() => import("./views/schedules/AddSchedule"))
const EditSchedule = React.lazy(() => import("./views/schedules/EditSchedule"))

// bastions 
const ListBastions = React.lazy(() => import("./views/bastions/ListBastions"))
const AddBastion = React.lazy(() => import("./views/bastions/AddBastion"))
const ViewBastion = React.lazy(() => import("./views/bastions/ViewBastion"))

const OKEList = React.lazy(async () => {
  try {
    const module = await import('./mkop-module-oke-dev/src/views/oke/ListOKEclusters');
    return module;
  } catch (e) {
    console.error("Failed to load OKEList", e);
    return { default: () => <div>Error loading OKEList</div> };
  }
});

const GetTOKECluster = React.lazy(async () => {
  try {
    const module = await import('./mkop-module-oke-dev/src/components/oke/getcluster/GetCluster');
    return module;
  } catch (e) {
    console.error("Failed to load GetTOKECluster", e);
    return { default: () => <div>Error loading GetTOKECluster</div> };
  }
});

const UpdateClusterOKE = React.lazy(async () => {
  try {
    const module = await import('./mkop-module-oke-dev/src/components/oke/updatecluster/UpdateOkeCluster');
    return module;
  } catch (e) {
    console.error("Failed to load UpdateClusterOKE", e);
    return { default: () => <div>Error loading UpdateClusterOKE</div> };
  }
});

const AddTOKECluster = React.lazy(async () => {
  try {
    const module = await import('./mkop-module-oke-dev/src/components/oke/addcluster/AddCluster');
    return module;
  } catch (e) {
    console.error("Failed to load GetCluster", e);
    return { default: () => <div>Error loading AddCluster</div> };
  }
});


const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/aws/clusters", exact: true, name: "EKS Clusters", component: Cluster },
  { path: "/aws/clusters/create", exact: true, name: "Create", component: AddCluster },
  { path: "/aws/clusters/update", exact: true, name: "Update", component: UpdateCluster },
  { path: "/aws/clusters/:id", exact: true, name: "Details", component: GetCluster },
  {
    path: "/aws/ExistingVpcCluster/update",
    exact: true,
    name: "Update",
    component: UpdateExsitingVPC
  },
  { path: "/aws/users", exact: true, name: "Users", component: Users },
  { path: "/gcp/gks", exact: true, name: "GKS", component: RequestGKS },
  { path: "/azure/aks", exact: true, name: "AKS", component: RequestAKS },


  {
    path: "/gcp/clusters/create",
    exact: true,
    name: "Create",
    component: GkeForm
  },
  {
    path: "/gcp/clusters",
    exact: true,
    name: "GCP Clusters",
    component: ListGkeClusters
  },
  {
    path: "/gcp/clusters/update",
    exact: true,
    name: "Update",
    component: UpdateGkeCluster
  },
  {
    path: "/gcp/clusters/:id",
    exact: true,
    name: "Details",
    component: GetGKECluster
  },
  {
    path: "/aws/ExistingVpcCluster/create",
    exact: true,
    name: "ExisitngVPC",
    component: ExistingVPC
  },
  {
    path: "/aws/ExistingVpcCluster",
    exact: true,
    name: "ExisitngVPC",
    component: ExistingVpcCluster
  },
  {
    path: "/aws/ExistingVpcCluster/:id",
    exact: true,
    name: "GetExisiting",
    component: GetExsitingVPC

  },
  {
    path: "/schedules",
    exact: true,
    name: "Schedules",
    component: Schedules

  },
  {
    path: "/schedules/details/:id",
    exact: true,
    name: "Details",
    component: ScheduleDetails

  },
  {
    path: "/schedules/create",
    name: "Create",
    component: AddSchedule

  },
  {
    path: "/schedules/update/:id",
    exact: true,
    name: "Update",
    component: EditSchedule
  },
  {
    path: "/bastions",
    exact: true,
    name: "Bastions",
    component: ListBastions
  },
  {
    path: "/bastions/create",
    exact: true,
    name: "Create",
    component: AddBastion
  },
  {
    path: "/bastions/view/:id/:name/:compartment",
    exact: true,
    name: "View",
    component: ViewBastion
  },
  {
    path: "/oke/clusters",
    exact: true,
    name: "OKE Clusters",
    component: OKEList
  },
  {
    path: "/oke/clusters/create",
    exact: true,
    name: "Create",
    component: AddTOKECluster
  }, {
    path: "/oke/clusters/update/:id",
    exact: true,
    name: "Update",
    component: UpdateClusterOKE
  },
  {
    path: "/oke/clusters/view/:id",
    exact: true,
    name: "Details",
    component: GetTOKECluster
  }
];

export default routes;
