import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, {instance, addAuthHeader} from '../../config/axios'

export const fetchOneEksCluster = createAsyncThunk('eks/fetchOneEksCluster', async (params) => {
    if(!axios?.defaults?.headers?.common['Authorization']){
      await addAuthHeader()
    }
    const res = await instance.get(`eks/${params}`)
    return res.data.data
})

const initialState = {
    cluster: {},
    loading: 'idle',
    error: null
}

const getEksClusterSlice = createSlice({
    name: 'getEksCluster',
    initialState,
    extraReducers(builder) {
        builder.addCase(fetchOneEksCluster.pending, state => {
            state.loading = 'pending'
        })
        builder.addCase(fetchOneEksCluster.fulfilled, (state, action) => {
            state.loading = 'succeeded'
            state.cluster = action.payload
        })
        builder.addCase(fetchOneEksCluster.rejected, state => {
            state.loading = 'failed'
            state.error = "Something went wrong. Please contact GKS support."
        })
    }
})
export const getOneEksCluster = state => state.getEksCluster.cluster
export const isEksLoading = state => state.getEksCluster.loading
export const getEksError = state => state.getEksCluster.error

export default getEksClusterSlice.reducer
